.navbar {
  height: auto;
  padding-right: 10px;
}

.navbar .navbar-brand {
  padding-left: 10px;
  font-family: Annai MN;
}

.navbar .nav-link {
  padding-left: 10px;
}

@media (min-width: 1000px) {
  .navbar .navbar-brand {
    text-align: center;
    width: calc(100vw - 400px);
    margin-left: 200px;
    font-size: 25px;
  }
}
