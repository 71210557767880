:root {
  --ui-border-radius: 8px;
  --ui-border-radius-form: 4px;
  --ui-border-radius-interaction: 1.2rem;
  --background: #FFFFFF;
  --content-background: #FFFFFF;
  --background-highlight-1: #e3be81;
  --background-highlight-2: #e3be81;
  --foreground: #1B1E1F;
  --foreground-subtitle: #4C5356;
  --foreground-placeholder: #7A8084;
  --foreground-highlight-1: #FFFFFF;
  --foreground-highlight-2: #FFFFFF;
  --border-color: #a8adb3;
  --success: #64A844;
  --warning: #FF9B00;
  --error: #DA0C1F;

  --bs-body-bg: var(--content-background);
  --bs-body-color: var(--foreground);
  --bs-border-color: var(--border-color);
  --bs-link-color: var(--foreground);
  --bs-link-hover-color: var(--background-highlight-1);
  --bs-danger-text-emphasis: var(--foreground);
  --bs-danger-bg-subtle: var(--error);
  --bs-danger-border-subtle: var(--border-color);
  --bs-border-radius: 0;
}

.pagination {
  --bs-pagination-active-bg: var(--background-highlight-2);
  --bs-pagination-active-border-color: var(--bs-border-color);
}

.navbar {
  background-color: var(--background-highlight-1);
  --bs-navbar-color: var(--foreground);
  --bs-navbar-brand-color: var(--foreground);
}

.nav-tabs {
  --bs-nav-tabs-link-hover-border-color: transparent;
}

.navbar-nav .dropdown-menu {
  --bs-dropdown-bg: var(--background-highlight-1);
  --bs-dropdown-color: var(--foreground-highlight-1);
  --bs-dropdown-link-color: var(--foreground-highlight-1);
  --bs-dropdown-link-hover-color: var(--foreground);
  --bs-dropdown-link-hover-bg: var(--content-background);
  --bs-dropdown-header-color: var(--foreground-placeholder);
  --bs-dropdown-link-active-color: var(--foreground);
  --bs-dropdown-link-active-bg: var(--content-background);
}

.alert {
  width: 40rem;
  height: 15rem;
  justify-self: center;
}

.spinner-container {
  display: grid;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.tooltip {
  border: 0.0625rem solid var(--border-color);
}

.tooltip .tooltip-inner {
  background-color: var(--background);
  color: var(--foreground);
}

.btn-primary {
  --bs-btn-bg: var(--background-highlight-2);
  --bs-btn-color: var(--foreground-highlight-2);
  --bs-btn-border-color: var(--border-color);
  --bs-btn-hover-bg: var(--background-highlight-2);
  --bs-btn-hover-color: var(--foreground-highlight-2);
  --bs-btn-hover-border-color: var(--border-color);
  --bs-btn-active-bg: var(--background-highlight-2);
  --bs-btn-active-color: var(--foreground-highlight-2);
  --bs-btn-active-border-color: var(--border-color);
  --bs-btn-disabled-bg: var(--background-highlight-2);
  --bs-btn-disabled-color: var(--foreground-highlight-2);
  --bs-btn-disabled-border-color: var(--border-color);
}

.btn-primary:hover {
  opacity: 80%;
}

.btn-primary:disabled {
  opacity: 60%;
}

.card-title {
  margin-bottom: 25px;
}

.recharts-default-tooltip {
  background-color: var(--content-background) !important;
  color: var(--foreground) !important;
}

html {
  height: 100%;
  width: 100%;
  background-color: var(--c-background-2);
  -webkit-transition: background-color .3s linear;
  -ms-transition: background-color .3s linear;
  transition: background-color .3s linear;
  overflow-x: hidden
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overscroll-behavior-y: none;
  font-size: 100%;
  background-color: var(--background);
  color: var(--foreground)
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

button,
label,
h1,
h2,
h3,
h4 {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -ms-user-select: none
}

* {
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern"
}

body,
h1,
h2,
h3,
h4 {
  font-size-adjust: .5
}

h5,
h6 {
  padding: 0 1em 0 0
}

h1 {
  font-size: 2em;
  line-height: 1.25
}

h2 {
  font-size: 1.625em;
  line-height: 1.15384615;
}

h3 {
  font-size: 1.375em;
  line-height: 1.13636364;
}

h4 {
  font-size: 1.125em;
  line-height: 1.11111111;
}

@media(min-width: 56.25em) {
  h1 {
    font-size: 3em;
    line-height: 1.05
  }

  h2 {
    font-size: 2.25em;
    line-height: 1.25
  }

  h3 {
    font-size: 1.75em;
    line-height: 1.25
  }
}

a:hover,
a:focus {
  text-decoration: none;
  color: #002349;
}

input::-webkit-datetime-edit-second-field:focus,
input::-webkit-datetime-edit-minute-field:focus,
input::-webkit-datetime-edit-hour-field:focus,
input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
  background-color: var(--background-highlight-1);
  color: var(--foreground-highlight-1);
  outline: none;
}

.tabs .nav-link {
  color: var(--background-highlight-2);
}

.tabs .nav-link:hover {
  color: var(--foreground-highlight-2);
  background-color: var(--background-highlight-2);
  border-bottom: 0.0625rem solid var(--border-color);
}

.tabs .nav-link.active {
  color: var(--background-highlight-2);
  background-color: transparent;
  font-weight: bold;
  border: none;
  border-bottom: 0.0625rem solid var(--background-highlight-2);
}

.tab-content {
  margin-top: 10px;
}
