.course-container {
  display: grid;
  padding-top: 20px;
  padding-bottom: 20px;
}

.course-container .courses {
  height: max-content;
  min-height: calc(100vh - 55px);
  max-width: 1500px;
  justify-self: center;
  display: grid;
  grid-auto-rows: 520px;
  row-gap: 20px;
}

@media (min-width: 1500px) {
  .course-container .courses {
    grid-template-columns: 750px 750px;
    column-gap: 20px;
  }
}

.course-container .courses .course-card {
  display: grid;
  height: auto;
  max-width: 750px;
  padding: 20px;
  grid-template-rows: 60px auto auto auto auto auto;
  grid-template-columns: 50px auto;
  grid-template-areas: "i h" "a a" "b b" "c c" "d d" "e e";
}

.course-container .courses .course-card .logo {
  grid-area: i;
  width: 40px;
  align-self: center;
  justify-self: center;
}

.course-container .courses .course-card .header {
  grid-area: h;
  margin-left: 15px;
  align-self: center;
}

.course-container .courses .course-card .course-part {
  display: grid;
  grid-template-columns: 120px auto;
}

.course-container .courses .course-card .when {
  grid-area: a;
}

.course-container .courses .course-card .who {
  grid-area: b;
}

.course-container .courses .course-card .where {
  grid-area: c;
}

.course-container .courses .course-card .how-much {
  grid-area: d;
}

.course-container .courses .course-card .sign {
  grid-area: e;
  display: grid;
  height: 30px;
}

.course-container .courses .course-card .sign .btn{
  width: 200px;
  margin-left: 120px;
}

.course-container .sign-up {
  display: grid;
  height: max-content;
  padding: 20px;
  grid-template-rows:
    150px auto repeat(4, 80px) minmax(120px, auto) auto repeat(7, 80px)
    35px 35px auto 50px 50px;
  row-gap: 10px;
}

.course-container .sign-up .course {
  padding: 0px 5px;
}

.course-container .sign-up .info-field {
  display: grid;
  grid-template-rows: 25px auto;
}

.course-container .sign-up .info-field .invalid {
  border: 0.0625rem solid red;
}

.course-container .sign-up .course {
  padding: 0px 5px;
}

.course-container .sign-up .accept-box {
  height: 30px;
}

.course-container .sign-up .notes {
  padding-top: 20px;
}

.course-container .sign-up .button {
  display: grid;
  padding-top: 20px;
  justify-content: right;
}

.course-container .sign-up .button .btn {
  width: 200px;
}

@media (min-width: 600px) {
  .course-container .courses .course-card {
    justify-self: center;
    width: 550px;
  }

  .course-container .sign-up {
    justify-self: center;
    width: 550px;
  }
}

.course-container .success-msg {
  display: grid;
  min-height: calc(100vh - 55px);
  max-width: 600px;
  padding: 20px;
  justify-self: center;
  grid-template-rows: 100px auto 50px 1fr;
  grid-template-columns: 50px auto;
  grid-template-areas: "i h" "a a" "b b" "c c";
}

.course-container .success-msg .logo {
  grid-area: i;
  width: 40px;
  align-self: center;
  justify-self: center;
}

.course-container .success-msg .header {
  grid-area: h;
  margin-left: 15px;
  align-self: center;
}

.course-container .success-msg .text {
  grid-area: a;
  padding: 30px 0px;
}

.course-container .success-msg .back {
  grid-area: b;
}
