.contact {
  display: grid;
  padding: 20px;
  background-color: var(--background-highlight-2);
}

.contact .contact-links {
  display: grid;
  justify-self: center;
  grid-template-rows: repeat(4, auto);
  row-gap: 30px;
}

.contact .contact-links .contact-headline {
  padding-bottom: 5px;
}

.contact .contact-links .contact-part {
  display: grid;
  grid-template-rows: 30px auto;
}

.contact .contact-links .contact-part .contact-part-header {
  display: grid;
  grid-template-columns: 30px auto;
  align-content: center;
}

.contact .contact-links .contact-part .contact-part-header .icon {
  height: 20px;
}

@media (min-width: 950px) {
  .contact .contact-links {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, 300px);
    grid-template-areas: "h h h" "a b c";
    row-gap: 30px;
  }

  .contact .contact-links .contact-headline {
    grid-area: h;
  }

  .contact .contact-links .contact-headline {
    text-align: center;
  }

  .contact .contact-links .contact-part {
    justify-self: center;
  }
}

