.home {
  display: grid;
}

.home .centered-text {
  text-align: left;
  padding: 0px 10px;
}

.home .img-container {
  width: 100vw;
  min-height: 600px;
  height: calc(100vh - 55px);
  overflow-y: hidden;
  position: relative;
}

.home .img-container #foot-and-hand-img {
  min-height: calc(100vh - 55px);
  max-height: calc(100vh - 55px);
  min-width: 100vw;
  max-width: 100vw;
  object-fit: cover;
  object-position: 70% 0;
}

.home .img-container #welcome-text {
  position: absolute;
  max-width: 600px;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

@media (min-height: 700px) {
  .home .img-container #welcome-text {
    top: 50%;
  }
}

@media (min-width: 1200px) {
  .home .home-headline {
    font-size: 65px;
  }

  .home .home-subtext {
    font-size: 25px;
  }

  .home .img-container #welcome-text {
    max-width: 800px;
  }
}

.home .about-me {
  display: grid;
  background-color: var(--background-highlight-2);
  padding: 20px 0px;
}

.home .about-me .image-container {
  display: grid;
  justify-self: center;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 50%;
}

.home .about-me .image-container #denise-herny-img {
  width: 100%;
}

.home .about-me .about-text {
  margin-top: 20px;
}

@media (min-width: 850px) {
  .home .about-me {
    justify-content: center;
    grid-template-columns: 400px 400px;
  }
}

.home .about-pekip {
  display: grid;
  padding-top: 20px;
}

.home .about-pekip .pekip {
  display: grid;
  height: max-content;
}

.home .about-pekip .pekip .logo {
  justify-self: center;
}

.home .about-pekip .denise-pekip {
  display: grid;
  height: max-content;
}

.home .about-pekip .denise-pekip .goals {
  margin-top: 20px;
}

.home .about-pekip .denise-pekip .pekip-text {
  margin-top: 20px;
}

.home .about-pekip .course-setup .course-text {
  margin-top: 20px;
}

@media (min-width: 950px) {
  .home .centered-text {
    text-align: left;
  }

  .home .about-pekip {
    justify-content: center;
    grid-template-columns: 440px 440px;
    grid-template-rows: auto auto;
    grid-template-areas: "p d" "c d";
    column-gap: 30px;
  }

  .home .about-pekip .pekip {
    grid-area: p;
    grid-template-rows: 140px auto;
  }

  .home .about-pekip .denise-pekip {
    grid-area: d;
    grid-template-rows: 140px auto;
  }

  .home .about-pekip .course-setup {
    grid-area: c;
  }
}
